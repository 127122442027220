<template>
  <div class="search">
    <!-- 上搜索背景 -->
    <div class="sousuo-beijing">
        <div class="sousuo-beijing-kuang">
           <div class="bangni-list">小邦规则议事厅</div>
           <div class="dingdanneirong">{{center.title}}</div>
           <div class="cjakan-list">
                <div class="toem-list">{{center.createtime}}</div>
                <div class="cjakan-list">
                    <img src="../assets/image/huiyan.png"/>
                    <div>{{center.browse_number}}</div>
                </div>
           </div>
        </div>
    </div>
    <div class="case-list">
        <!-- 内容 -->
      <div class="case-beijing" >
        <div class="suoyou-center" v-html="center.details.replace(/\n/g,'<br/>')"></div>
      </div>
      <!-- 观点 -->
      <div class="case-xianyougaundina">
        <div class="gaunidna-name">现有观点</div>
        <div class="guandian-list">
            <div class="guanli-suoyou" v-for="(item,index) in center.extend" :key="index">
                <div :class="item.id==1?'ganli-totle':'ganli-totle-huang'" >{{item.title}}</div>
                <div class="guandian-dianzan-list" v-for="(itema,indexa) in item.child" :key="indexa">
                    <div class="neiofn">{{itema.title}}</div>
                    <div class="guanli-dainzan" @click="dianzan(item,itema)" :class="itema.is_thumb==1?'altoce':''">
                        <img v-if="itema.is_thumb==0" src="../assets/image/huidainzan.png"/>
                        <img v-else src="../assets/image/baidianzan.png"/>
                        <div>{{itema.format_thumb_number}}</div>
                    </div>
                </div>
            </div>
            <!-- <div class="guanli-suoyou">
                <div class="ganli-totle-huang">顾客给商家差评不合适</div>
                <div class="guandian-dianzan-list" v-for="(item,index) in 3" :key="index">
                    <div class="neiofn">①备注不在商家经营范围内就是无理需求，喜</div>
                    <div class="guanli-dainzan" @click="budianzan()" :class="dzind==true?'altoce':''">
                        <img v-if="!dzind" src="../assets/image/huidainzan.png"/>
                        <img v-else src="../assets/image/baidianzan.png"/>
                        <div>2k+</div>
                    </div>
                </div>
            </div> -->
        </div>
      </div>
      <!-- 投票 -->
      <div class="toupiao-list">
        <div class="toupiao-tiel">投票</div>
        <div class="suouou-toupiao-list">
            <div class="suoyou-antile-name">{{center.title}}</div>
            <div class="toupaio-zing">投票进行中</div>
            <div class="jindutuao-lie-list">
                <div class="jindutuao-lie-title">
                    <div>{{center.extend[0].title}}</div>
                    <div>{{center.extend[1].title}}</div>
                </div>
                <div class="jindutuao-lie">
                    <div class="jindu-chen" :style='`width:calc(${ iszero(center.extend[0].thumb_number,center.extend[1].thumb_number,true) * 100}% - 25px)`'>
                        <div>{{Math.round( iszero(center.extend[0].thumb_number,center.extend[1].thumb_number,true) *100)}}%</div>
                    </div>
                    <div class="zhongjian-img">
                        <img src="../assets/image/icon_cleft.png"/>
                        <img src="../assets/image/icon_lanright.png"/>
                    </div>
                    <div class="jindu-lan" :style='`width:calc(${ (iszero(center.extend[0].thumb_number,center.extend[1].thumb_number,false)) * 100}% - 25px)`'>
                        <div>{{Math.round((iszero(center.extend[0].thumb_number,center.extend[1].thumb_number,false)) * 100)}}%</div>
                    </div>
                </div>
            </div>
            <div class="titl-ing">*投票结果和观点为平台治理提供参考</div>
        </div>
        
      </div>
      <!-- 点赞特效图片 -->
      <div class="dianzia-img">
        <img class="fjgodfj-immg" :class="dzdd==true?'ampay':''" src="../assets/image/huangbigdianzan.png"/>
      </div>
    </div>
   
  </div>
</template>


<script>
  export default {
    name:"caseyushutdetails",
    data () {
      return {
        input:'',
        mocten:[],
        show:false,
        su:0.5,
        dianzanid:false,
        dzind:false,
        dzdd:false,
        detailid:'',
        center:{}
      }
    },
    mounted () {
      this.detailid=this.$route.query.id
      window.scrollTo(0, 0);
      // this.su = (2/(center.extend[0].thumb_number+center.extend[1].thumb_number))
      this.getsousuolist()
    },
    methods: {
      //判断0
      iszero(e,f,type){
        if(e == 0 && f == 0){
          return 0.5
        }
        if(type){
          return (e/(e+f))
        }else{
          return (f/(e+f))
        }
      },
      // 点赞
      dianzan(item,data){
        console.log(item,data,'^^^^')
         let that = this
        that.$http({
          url: that.baseurl +"/doThumb",
          method: "post",
          headers:{
           "web-token":localStorage.getItem('auto_token')
          },
          data:{
            rule_id:this.detailid,
            question_id:item.id,
            answer_id:data.id
          }
        })
          .then(res => {
              if(res.data.code==200){
                  that.$message.success(res.data.data.thumb_msg);
                  that.getsousuolist()
              }else{
                  that.$message.error(res.data.msg);
                  // that.$router.push('/')
              }
              
          })
          .catch(function(error) {
              //  that.$message.error(res.data.message);
        });
        this.dzdd = !this.dzdd
        setTimeout(()=>{
          this.dzdd = false
        },2000)
      },
      budianzan(){
        this.dzind = !this.dzind
      },
      getsousuolist(){
        let that = this
        that.$http({
          url: that.baseurl +"/getRuleInfo?id="+ that.detailid,
          method: "get",
          headers:{
           "web-token":localStorage.getItem('auto_token')
          },
          data:{}
        })
          .then(res => {
              if(res.data.code==200){
                  // that.$message.success(res.data.data);
                  that.center = res.data.data
              }else{
                  that.$message.error(res.data.msg);
                  // that.$router.push('/')
              }
              
          })
          .catch(function(error) {
              //  that.$message.error(res.data.message);
        });
      },
    
      handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      }
    },
  }

</script>
<style scoped>
  .search{
    width: 100%;
    background-color: #F2F5FB ;
  }
  .sousuo-beijing{
    width: 100%;
    height: 418px;
    background: url('../assets/image/sousuobeiijingtu.png') no-repeat; 
    background-size: cover;
    position: relative;
  }
  .sousuo-beijing-kuang{
    position: absolute;
    top:40%;
    left:23% ;
    padding: 0 20px;
  }

  .case-list{
    width: 100%;
    max-width: 1520px;
    /* height: 500px; */
    margin: 0px auto;
    padding-bottom: 20px;
  }
  .bangni-list{
    font-weight: 550;
    font-size: 16px;
    color: #333333;
  }
  .dingdanneirong{
    padding: 24px 0 40px 0;
    font-weight: 550;
    font-size: 32px;
    color: #275AF5;
  }
  .cjakan-list{
    display: flex;
    color: #999999;
    font-size: 14px;
  }
  .toem-list{
    margin-right: 20px;
  }
  .cjakan-list img{
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }
  .case-beijing{
    width: 100%;
    height: 620px;
    background-color: #FFFFFF;
    border-radius: 8px;
    margin-top: 40px;
  }
  .case-xianyougaundina{
    margin-top: 15px;
  }
  .gaunidna-name{
    font-weight: 500;
    font-size: 24px;
    color: #333333;
    padding: 20px 0;
  }
  .suoyou-center{
    padding: 24px;
    white-space: pre-wrap;
  }
  .guandian-list{
    width: 100%;
    min-height: 542px;
    background: #FFFFFF;
    border-radius: 8px
  }
  .guanli-suoyou{
    padding: 20px 20px;
  }
  .ganli-totle{
    font-weight: 550px;
    font-size: 18px;
    color: #FF8312;
    padding: 40px 0;
  }
  .guandian-dianzan-list{
    font-size: 14px;
    color: #333333;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .neiofn{
    width: 60%;
    padding: 0 0 42px 0;
  }
  .guanli-dainzan{
    width: 123px;
    height: 38px;
    border-radius: 19px;
    border: 1px solid #DDDDDD;
    line-height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #333333;
  }
  .guanli-dainzan img{
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
  .ganli-totle-huang{
    font-weight: 550px;
    font-size: 18px;
    color: #275AF5;
    padding: 0px 0 40px 0;
  }
  .toupiao-list{
    margin-top: 15px;
  }
  .toupiao-tiel{
    font-weight: 500;
    font-size: 24px;
    color: #333333;
    padding: 20px 0;
  }
  .suouou-toupiao-list{
    width: 100%;
    height: 421px;
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 0 20px;
  }
  .suoyou-antile-name{
    font-weight: 550;
    font-size: 24px;
    color: #333333;
  }
  .toupaio-zing{
    font-size: 14px;
    color: #999999;
  }
  .suoyou-antile-name{
    padding: 40px 0 20px 0;
  }
  .jindutuao-lie-list{
    margin: 50px auto;
  }
  .jindutuao-lie-title{
     width: 60%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
  }
  .jindutuao-lie-title div:nth-child(1){
    font-weight: 550;
    font-size: 18px;
    color: #FF8312;
  }
  .jindutuao-lie-title div:nth-child(2){
    font-weight: 550;
    font-size: 18px;
    color: #275AF5;
  }
  .jindutuao-lie{
    width: 60%;
    display: flex;
    align-items: center;
    margin: 12px auto;
  }
  .jindu-chen{
    background-color: #FF8312;
    border-radius: 30px 0 0px 30px;
    width: 55%;
    height: 58px;
    line-height: 58px;
    font-size: 24px;
    color: #FFFFFF;
    min-width: 15%;
  }
  .jindu-chen div{
    margin-left: 45px;
  }
  .zhongjian-img{
    /* width: 50px; */
    display: flex;
    /* border: 1px solid #DDDDDD; */
    height: 58px;
  }
  .zhongjian-img img{
    width: 34px;
    height: 58px;
  }
  .jindu-lan{
    background-color: #275AF5 ;
    border-radius: 0px 30px 30px 0px;
    width: 40%;
    height: 58px;
    line-height: 58px;
    font-size: 24px;
    color: #FFFFFF;
    min-width: 15%;
  }
  .jindu-lan div{
     display: flex;
     justify-content: flex-end;
     margin-right: 40px;
  }
  .titl-ing{
    font-size: 14px;
    color: #999999;
    text-align: center;
  }
  .altoce{
    background-color: #FFE504;
    color: #FFFFFF;
    border: 1px solid #FFE504;
  }
  .dianzia-img{
    position: relative;
  }
  .fjgodfj-immg{
    width: 0;
    height: 0;
    position: fixed;
    left: 50%;
    bottom:50%;
     opacity:0;
  }
  @keyframes showIcon {
    100% {
        opacity:0;
    }
    80%{
        opacity: 1;
        width:78px;
        height: 78px;
        left: calc(50% - 39px);
        top: calc(50% - 39px);
    }

    20%{
        opacity: 1;
        width: 78px;
        height:78px;
        left: calc(50% - 39px);
        top: calc(50% - 39px);
    }
    0% {
        opacity: 0;
    }
}
.ampay{
    animation: 2s showIcon;
    animation-iterationcount: 1;
    animation-direction:alternate;
}
</style>
<style >
.sousuo-beijing-kuang .el-input__inner{
    width: 85%;
    height: 56px !important;
    border-radius: 8px !important;
    border: none !important;
}
.case-guizezhongxin-daohang .el-submenu__title{
  padding: 0;
}
.case-guizezhongxin-daohang .el-menu-item .is-active {
    font-size: 16px;
    color: #275AF5;
}
.case-guizezhongxin-daohang .el-menu-item .is-activer{
  background: rgba(39, 90, 245, 0.08);
}
</style>
